<script>
  import PNotify from './PNotify.html';

  let _open = PNotify.prototype.open;
  let _close = PNotify.prototype.close;

  const callbacks = (notice, options, name) => {
    let modules = notice ? notice.get().modules : options.modules;
    let cbs = (modules && modules.Callbacks) ? modules.Callbacks : {};
    return cbs[name] ? cbs[name] : () => true;
  };

  PNotify.prototype.open = function (...args) {
    let ret = callbacks(this, null, 'beforeOpen')(this);
    if (ret !== false) {
      _open.apply(this, args);
      callbacks(this, null, 'afterOpen')(this);
    }
  };

  PNotify.prototype.close = function (timerHide, ...args) {
    let ret = callbacks(this, null, 'beforeClose')(this, timerHide);
    if (ret !== false) {
      _close.apply(this, [timerHide, ...args]);
      callbacks(this, null, 'afterClose')(this, timerHide);
    }
  };

  export default {
    setup (Component) {
      Component.key = 'Callbacks';

      Component.getCallbacks = callbacks;

      let _alert = PNotify.alert;
      let _notice = PNotify.notice;
      let _info = PNotify.info;
      let _success = PNotify.success;
      let _error = PNotify.error;

      let init = (original, options) => {
        callbacks(null, options, 'beforeInit')(options);
        let notice = original(options);
        callbacks(notice, null, 'afterInit')(notice);
        return notice;
      };

      PNotify.alert = (options) => {
        return init(_alert, options);
      };
      PNotify.notice = (options) => {
        return init(_notice, options);
      };
      PNotify.info = (options) => {
        return init(_info, options);
      };
      PNotify.success = (options) => {
        return init(_success, options);
      };
      PNotify.error = (options) => {
        return init(_error, options);
      };

      // Register the module with PNotify.
      PNotify.modules.Callbacks = Component;
    }
  };
</script>
